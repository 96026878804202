import { InboundError, TransferPalletActionSource } from "@deliverr/legacy-inbound-client";
import { log, logError, logStart, isScanInput } from "@deliverr/ui-facility/lib-facility/utils";
import { useSetRecoilState, useRecoilState, SetterOrUpdater, useRecoilValue } from "recoil";
import {
  LoadingDockScanEventApiErrorCode,
  LoadingDockScanEventResponseWithWarehouseId,
  LoadingDockScanEventScanType,
} from "@deliverr/freight-client";
import { Text, toast } from "@deliverr/ui";
import { FormattedMessage } from "react-intl";

import {
  palletIdState,
  trackingCodeState,
  destinationMismatchContextState,
  DestinationMismatchContext,
} from "crossdock/modes/transfers/ShipPallet/ShipPalletState";
import { FlowCardData } from "@deliverr/ui-facility/lib-facility/flow/types";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { validateMilkRun } from "@deliverr/ui-facility/lib-facility/utils/commonsTransferValidationUtils";
import {
  createErrorTextCard,
  createUnknownErrorCard,
  createUnknownInputCard,
} from "@deliverr/ui-facility/lib-facility/flow/flowCardCreators";
import {
  createShipPalletScanTrackingCard,
  createShipPalletScanTrackingSuccessCard,
  createShipPalletScanPalletCard,
  createShipPalletTrackingInUseCard,
} from "crossdock/common/flow/scanFlowCardCreators";
import { useCrossdockModal } from "crossdock/common/modal";
import { TransfersModal } from "crossdock/modes/transfers/modals/TransfersModal";
import { ModalGenericProps } from "@deliverr/ui-facility";
import { FlashType } from "@deliverr/ui-facility/lib-facility/components/Flash";
import { userState } from "@deliverr/ui-facility/lib-facility/base/Auth/userState";
import { crossdockClient, freightClient } from "crossdock/base/Clients";
import React, { useRef } from "react";
import { getValidatedTrackingInfo } from "./transferValidationUtils";
import { createThrottledFunction } from "crossdock/common/createThrottledFunction";

export function submitUpdateTracking(
  addFlowCard: (flowCard: FlowCardData<any>) => void,
  hideAllFlowButtons: () => void,
  setPalletId: SetterOrUpdater<string>,
  setTrackingCode: SetterOrUpdater<string>,
  setDestinationMismatchContext: SetterOrUpdater<DestinationMismatchContext>,
  showModal: (modalType: string, props: ModalGenericProps) => void,
  resetFlowCards: () => void,
  emitFlash: (type: FlashType) => void,
  warehouseId: string,
  isUnbundled: boolean
) {
  return async (palletId: string, trackingCode: string) => {
    const ctx = logStart({ fn: "submitUpdateTracking", palletId, trackingCode });

    const { isValid, usableTrackingCode, carrier } = getValidatedTrackingInfo(trackingCode);
    const reqOptions = { carrier, actionSource: TransferPalletActionSource.TRANSFERS_SHIP_PALLET };
    const isMilkRun = validateMilkRun(usableTrackingCode);

    if (!isValid) {
      log(ctx, "invalid tracking code");
      emitFlash("DANGER");
      setTrackingCode("");
      addFlowCard(
        createUnknownInputCard({
          value: usableTrackingCode,
          message: "Does not appear to be a valid tracking code",
        })
      );
      addFlowCard(createShipPalletScanTrackingCard({ isUnbundled }));
      return;
    }

    let numOfPalletsLinkedToTracking: number;
    try {
      numOfPalletsLinkedToTracking = isUnbundled
        ? (
            (await freightClient.createWarehouseLoadingDockScanEvent({
              barcode: palletId,
              scanType: LoadingDockScanEventScanType.LINK_TO_BOL,
              warehouseId,
              bolId: usableTrackingCode,
            })) as LoadingDockScanEventResponseWithWarehouseId
          ).palletsScannedAtLocation
        : (
            await crossdockClient.updateTrackingByLabelId({
              palletLabelId: palletId,
              trackingCode: usableTrackingCode,
              warehouseId,
              options: reqOptions,
            })
          ).numOfPalletsLinkedToTracking;
      emitFlash("SUCCESS");
      setPalletId("");
      setTrackingCode("");
      addFlowCard(
        createShipPalletScanTrackingSuccessCard({
          palletId,
          trackingCode: usableTrackingCode,
          numOfPalletsLinkedToTracking,
        })
      );
      addFlowCard(createShipPalletScanPalletCard({}));
      hideAllFlowButtons();
    } catch (err) {
      emitFlash("DANGER");
      setTrackingCode("");

      if (Object.values(InboundError).includes(err.code)) {
        switch (err.code) {
          case InboundError.TRACKING_CODE_IN_USE:
            log(ctx, "inbounds tracking code in use", err);
            addFlowCard(
              createShipPalletTrackingInUseCard({ palletId, trackingCode: err?.payload?.duplicate?.trackingCode })
            );
            addFlowCard(createShipPalletScanTrackingCard({ isUnbundled }));
            break;
          case InboundError.INVALID_TRACKING_CODE:
            log(ctx, "inbounds invalid tracking code", err);
            addFlowCard(
              createUnknownInputCard({
                value: usableTrackingCode,
                message: "Does not appear to be a valid tracking code",
              })
            );
            addFlowCard(createShipPalletScanTrackingCard({ isUnbundled }));
            break;
          case InboundError.PALLET_DESTINATION_WAREHOUSE_MISMATCH:
            log(ctx, "inbounds pallet destination mismatch", err);
            resetFlowCards();
            setDestinationMismatchContext({
              expectedDestinationWarehouseId: err.payload.existingPalletToWarehouseId ?? err.payload.milkRunDestination,
              currentPalletToWarehouseId: err.payload.currentPalletToWarehouseId ?? err.payload.palletDestination,
              isMilkRun,
            });
            showModal(TransfersModal.TRANSFERS_DESTINATION_MISMATCH, {});
            addFlowCard(createShipPalletScanTrackingCard({ isUnbundled }));
            break;
          case InboundError.MILK_RUN_DESTINATION_WAREHOUSE_MISMATCH:
            log(ctx, "inbounds milk run / pallet destination mismatch", err);
            resetFlowCards();
            setDestinationMismatchContext({
              expectedDestinationWarehouseId: err.payload.milkRunDestinationWarehouseId,
              currentPalletToWarehouseId: err.payload.palletDestinationWarehouseId,
              isMilkRun,
            });
            showModal(TransfersModal.TRANSFERS_DESTINATION_MISMATCH, {});
            addFlowCard(createShipPalletScanTrackingCard({ isUnbundled }));
            break;
          default:
            logError(ctx, err);
            addFlowCard(createUnknownErrorCard({ value: usableTrackingCode }));
            addFlowCard(createShipPalletScanTrackingCard({ isUnbundled }));
            break;
        }
      } else {
        switch (err.response?.data?.code) {
          case LoadingDockScanEventApiErrorCode.UNKNOWN_BOL:
            log(ctx, "freight unknown BOL");
            toast.error(
              <FormattedMessage
                id="crossdock.transferPallets.unbundled.unknownBOL"
                defaultMessage="Please scan a valid BOL"
              />,
              {
                autoClose: false,
                toastId: "unbundledTransferPalletUnknownBOLError",
              }
            );
            break;
          case LoadingDockScanEventApiErrorCode.MISMATCHED_BOL_PALLET_LABEL_DESTINATIONS:
            log(ctx, "freight pallet destination mismatch");
            addFlowCard(
              createErrorTextCard({
                value: palletId,
                title: "Pallet can't be linked to BOL",
                message: (
                  <Text>
                    This pallet&apos;s destination is{" "}
                    <Text as="span" bold>
                      {err.response.data.payload.palletToWarehouseId ?? err.response.data.payload.palletToStreet}
                    </Text>
                    . The BOL you scanned is not bound to that destination.
                  </Text>
                ),
              })
            );
            addFlowCard(createShipPalletScanTrackingCard({ isUnbundled }));
            break;
          default:
            logError(ctx, err);
            addFlowCard(createUnknownErrorCard({ value: usableTrackingCode }));
            addFlowCard(createShipPalletScanTrackingCard({ isUnbundled }));
            break;
        }
      }
    }
  };
}

export function useShipPalletScanTracking({ isUnbundled }: { isUnbundled: boolean }) {
  const { addFlowCard, hideAllFlowButtons, resetFlowCards, emitFlash } = useScanFlow();
  const [palletId, setPalletId] = useRecoilState(palletIdState);
  const [trackingCode, setTrackingCode] = useRecoilState(trackingCodeState);
  const { showModal } = useCrossdockModal();
  const setDestinationMismatchContext = useSetRecoilState(destinationMismatchContextState);
  const { warehouseId } = useRecoilValue(userState);

  const throttledSubmitRef = useRef(
    createThrottledFunction(
      submitUpdateTracking(
        addFlowCard,
        hideAllFlowButtons,
        setPalletId,
        setTrackingCode,
        setDestinationMismatchContext,
        showModal,
        resetFlowCards,
        emitFlash,
        warehouseId,
        isUnbundled
      ),
      1000 // 1 second throttle delay
    )
  );

  async function onTrackingCodeChange(newTrackingCode: string) {
    const formattedTrackingCode = newTrackingCode.trim().toUpperCase();
    setTrackingCode(formattedTrackingCode);
    if (isScanInput(formattedTrackingCode, trackingCode)) {
      await throttledSubmitRef.current(palletId, formattedTrackingCode);
    }
  }

  return {
    palletId,
    trackingCode,
    onTrackingCodeChange,
    submitUpdateTracking: throttledSubmitRef.current,
  };
}
