export function createThrottledFunction<T extends (...args: any[]) => Promise<any>>(fn: T, delay: number): T {
  let pending = false;
  let timeoutId: NodeJS.Timeout | null = null;

  return (async (...args: Parameters<T>) => {
    if (pending) {
      return;
    }

    pending = true;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    try {
      return await fn(...args);
    } finally {
      timeoutId = setTimeout(() => {
        pending = false;
      }, delay);
    }
  }) as T;
}
